import Header from "./Header"
import Main from "./Main"

export default function Body() {
    return (
        <>
            <Header />
            <Main />
            <br />
            <br />
            <br />
        </>
    )
}