import React from 'react'
import ReactDOM from 'react-dom'
import Body from './Body'

ReactDOM.render(
    <React.StrictMode>
        <Body />
    </React.StrictMode>,
    document.getElementById('root')
)


